import React from "react";
import { HiOutlinePencil, HiOutlineTerminal } from "react-icons/hi";
import { VscRocket } from "react-icons/vsc";
import ReactRotatingText from "react-rotating-text";
import Page from "components/Page";
import ColourSection from "components/ColourSection";
import Header from "components/Header";
import Footer from "components/Footer";
import Columns from "components/Columns";
import FadeIn from "components/FadeIn";
import LogoGrid from "components/LogoGrid";

const Services = () => (
	<>
		<Page
			minHeight="90vh"
			top={null}
			middle={(
				<FadeIn>
					<h1>
						Our
						{" "}
						<ReactRotatingText items={["services", "skills", "talents", "knowledge", "passion", "strengths"]} randomize color="var(--accent-color)" />
					</h1>
					<p>
						Our recipe for delivering successful user-led digital experiences, products,
						services, apps and websites, is creating a strong strategy and implementing
						it through our creative design capabilities combined with the latest, most
						appropriate technology.
					</p>
				</FadeIn>
			)}
			bottom={null}
		/>

		<ColourSection backgroundColor="var(--grey-color-light)">
			<Page
				minHeight="50vh"
				top={<h1><VscRocket /></h1>}
				middle={(
					<FadeIn>
						<Columns>
							<div className="column">
								<h3>Strategy</h3>
								<p>
									Our methodology is based on human-centred research and proven design
									thinking methods. Then we recommend action that leads to measurably
									better results.
								</p>
							</div>
							<div className="column">
								<ul className="light">
									<h3>&nbsp;</h3>
									<li><FadeIn random>Discovery and Research</FadeIn></li>
									<li><FadeIn random>Brief development</FadeIn></li>
									<li><FadeIn random>Analysis</FadeIn></li>
									<li><FadeIn random>Project evaluation</FadeIn></li>
									<li><FadeIn random>Project scoping</FadeIn></li>
									<li><FadeIn random>Brand positioning</FadeIn></li>
									<li><FadeIn random>Product management / roadmap</FadeIn></li>
									<li><FadeIn random>+</FadeIn></li>
								</ul>
							</div>
						</Columns>
					</FadeIn>
				)}
				bottom={null}
			/>
		</ColourSection>

		<ColourSection backgroundColor="var(--grey-color-light)">
			<Page
				minHeight="50vh"
				top={<h1><HiOutlinePencil /></h1>}
				middle={(
					<FadeIn>
						<Columns>
							<div className="column">
								<h3>Design</h3>
								<p>
									Great design is essential to successful business. Combined with your
									industry and customer insight, our creative team brings their design
									and business skills to create memorable and effective experiences for
									your customers.
								</p>
							</div>
							<div className="column">
								<ul className="light">
									<h3>&nbsp;</h3>
									<li><FadeIn random>Concept design and development</FadeIn></li>
									<li><FadeIn random>User experience design (UX)</FadeIn></li>
									<li><FadeIn random>User interface design (UI)</FadeIn></li>
									<li><FadeIn random>Mobile app design</FadeIn></li>
									<li><FadeIn random>Website design</FadeIn></li>
									<li><FadeIn random>Copywriting</FadeIn></li>
									<li><FadeIn random>Apps</FadeIn></li>
									<li><FadeIn random>Branding</FadeIn></li>
									<li><FadeIn random>Websites</FadeIn></li>
									<li><FadeIn random>Motion graphics</FadeIn></li>
									<li><FadeIn random>Video production</FadeIn></li>
									<li><FadeIn random>Events</FadeIn></li>
									<li><FadeIn random>+</FadeIn></li>
								</ul>
							</div>
						</Columns>
					</FadeIn>
				)}
				bottom={null}
			/>
		</ColourSection>

		<ColourSection backgroundColor="var(--grey-color-light)">
			<Page
				minHeight="50vh"
				top={<h1><HiOutlineTerminal /></h1>}
				middle={(
					<FadeIn>
						<Columns>
							<div className="column">
								<h3>Development</h3>
								<p>
									With clear communication, collaboration and transparency, our agile
									development process means that each project goes through, seamlessly,
									from scoping to development and executing.
								</p>
								<p>
									From mobile to enterprise-scale apps, our work is always built for
									reliability, flexibility, and scale.
								</p>
							</div>
							<div className="column">
								<ul className="light">
									<h3>&nbsp;</h3>
									<li><FadeIn random>Prototyping</FadeIn></li>
									<li><FadeIn random>Full-stack</FadeIn></li>
									<li><FadeIn random>SaaS</FadeIn></li>
									<li><FadeIn random>React.js (cross-platform)</FadeIn></li>
									<li><FadeIn random>iOS and Android native development</FadeIn></li>
									<li><FadeIn random>Backend</FadeIn></li>
									<li><FadeIn random>QA and testing</FadeIn></li>
									<li><FadeIn random>Front-end development</FadeIn></li>
									<li><FadeIn random>Server-side development</FadeIn></li>
									<li><FadeIn random>Database Architecture</FadeIn></li>
									<li><FadeIn random>Content Management Systems</FadeIn></li>
									<li><FadeIn random>E-commerce</FadeIn></li>
									<li><FadeIn random>+</FadeIn></li>
								</ul>
							</div>
						</Columns>
					</FadeIn>
				)}
				bottom={null}
			/>
		</ColourSection>

		<Page
			top={(
				<FadeIn>
					<h4>Clients</h4>
					<p>
						Our expertise and experience spans Travel and Leisure, Health &amp; Fitness,
						Healthcare, Pharmaceutical, high-end Audio Manufacture, Finance, Payroll
						Management, Veterinary, SaaS, Developer tools, Food &amp; Beverage, Mobile
						&amp; Tech industries, Broadcast… and much more.
					</p>
				</FadeIn>
			)}
			middle={(
				<FadeIn>
					<LogoGrid />
				</FadeIn>
			)}
			bottom={null}
		/>
		<Footer />
		<Header />
	</>
);

export default Services;
